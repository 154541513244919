<template>
  <div id="Gallery">
    <MyHeader />
    <div class="block1">
      <h3>Gallery</h3>
      <swiper v-if="productList.length > 0" :options="swiperOption" ref="mySwiper">
        <swiper-slide class="swiper-slide" v-for="(item, index) of productList" :key="index">
          <div class="each-slide-div" v-for="(itemx, inx) of item" :key="inx">
            <img :src="itemx.imagesUrl">
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <div class="block2"></div>
    <MyFooter />
  </div>
</template>

<script>
import * as Api from '@pm'
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'
// import Swiper from 'swiper'
// 引入插件
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'Gallery',
  components: { MyHeader, MyFooter, swiper, swiperSlide },
  data() {
    return {
      productList: [],
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        // slidesPerColumn: 2,
        slidesPerGroup: 1,
        // loopFillGroupWithBlank: true,
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  mounted() {
    this.getProductList()
  },
  methods: {
    getProductList() {
      let that = this
      that.productList = []
      Api.getPGallery().then((result) => {
        if (result.data.code == 200) {
          let productRec = result.data.data || []
          that.productList = that.group(productRec, 6) // .concat(productRec)
        }
      })
    },
    group(array, subGroupLength) {
      let index = 0
      let newArray = []
      while (index < array.length) newArray.push(array.slice(index, (index += subGroupLength)))
      return newArray
    }
  }
}
</script>
<style lang="less" scoped>
#Gallery {
  .block1 {
    width: 1440px;
    margin: 0 auto;
    h3 {
      width: 920px;
      margin: 0 auto;
      text-align: center;
      text-align: center;
      font-size: 48px;
      font-family: DINNextLTPro-Medium;
      line-height: 68px;
      color: #3b3b3b;
    }
    .carouselbox {
      width: 1440px;
      margin: 0 auto;
      position: relative;
    }
  }
  .block2 {
    margin-top: 70px;
  }
}
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 38px;
  > div,
  .each-slide-div {
    // display: flex;
    width: 400px;
    height: 272px;
    // margin: 38px 0;
    &:nth-child(3n-1) {
      margin: 0 38px;
    }
    margin-bottom: 38px !important;
    img {
      width: 100%;
      height: 272px;
    }
  }
}
</style>